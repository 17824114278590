import { useCallback, useEffect, useMemo, useState } from "react";
import { ConfirmForm, PatientDetails, Prescription } from "../models";
import { sendGAEvent } from "../helpers";

interface Props {
  patient: PatientDetails;
  prescription: Prescription;
  confirmForm: ConfirmForm;
  setConfirmForm: Function;
  handleNextClick: Function;
}
const Confirm = ({
  patient,
  prescription,
  confirmForm,
  setConfirmForm,
  handleNextClick,
}: Props) => {
  const [buttonCssClass, setButtonCssClass] = useState("");
  const requiredProps = useMemo(() => ["agreeTCs", "acknowledge"], []);

  const handleClick = (e: any): void => {
    const { name, value, checked } = e.target;
    setConfirmForm(value);
    const newConfirmForm = {
      ...confirmForm,
      [name]: checked,
    };
    setConfirmForm(newConfirmForm);
    updateButtonCssClass(newConfirmForm);
  };

  const updateButtonCssClass = useCallback(
    (confirmForm: ConfirmForm): void => {
      let isButtonActive = true;
      const map = new Map(Object.entries(confirmForm));
      requiredProps.forEach((prop) => {
        if (!map.get(prop)) {
          isButtonActive = false;
        }
      });
      setButtonCssClass(isButtonActive ? "active" : "");
    },
    [requiredProps]
  );

  const validate = () => {
    let errorsExist = false;

    const validationErrors: string[] = [];
    const addError = (fieldName: string) => {
      validationErrors.push(fieldName);
      errorsExist = true;
    };

    const map = new Map(Object.entries(confirmForm));
    requiredProps.forEach((prop) => {
      if (!map.get(prop)) {
        addError(prop);
      }
    });

    if (!errorsExist) {
      sendGAEvent("confirmation", 9, "Confirmation", patient.maskedEmail, {
        prescription_type: prescription.repeat ? "repeat" : "new",
        value: prescription.price,
      });
      handleNextClick();
    }
  };
  useEffect(() => {
    updateButtonCssClass(confirmForm);
  });

  return (
    <>
      <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
        <div className="cs-chkin-form-content step summary col-xs-11">
          <div className="dark-content-box" id="summary_details">
            <div style={{ width: "100%" }}>
              <div className="mb-4">
                <div className="sub-heading">Prescription details for:</div>
                <div className="summary-information" id="summary_name">
                  {confirmForm.name}
                </div>
              </div>

              <div className="mb-4">
                <div className="sub-heading">Phone number:</div>
                <div className="summary-information" id="summary_mobile">
                  {confirmForm.mobileNo}
                </div>
              </div>

              <div className="mb-4">
                <div className="sub-heading">Email address:</div>
                <div className="summary-information" id="summary_email">
                  {confirmForm.email}
                </div>
              </div>

              <div>
                <div className="sub-heading">Prescription type:</div>
                <div className="summary-information" id="summary_consult_type">
                  {confirmForm.prescriptionType}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mb-0">
            <div className="form-inline rowGap-2" id="consent_form_group">
              <label className="checkbox-container pt-2" id="agree_group">
                <small>
                  I agree to the{" "}
                  <a
                    href="https://www.oursage.com.au/terms-of-use"
                    target="_blank"
                    rel="noreferrer"
                  >
                    T&Cs
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.oursage.com.au/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </small>
                <input
                  type="checkbox"
                  id="agreeTCs"
                  name="agreeTCs"
                  defaultChecked={confirmForm.agreeTCs}
                  onClick={handleClick}
                />
                <span className="checkmark"></span>
              </label>

              <label className="checkbox-container pt-2" id="acknowledge_group">
                <small>
                  I acknowledge that a Medicare rebate will not be available for
                  this telehealth consult.
                </small>
                <input
                  type="checkbox"
                  id="acknowledge"
                  name="acknowledge"
                  defaultChecked={confirmForm.acknowledge}
                  onClick={handleClick}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>

          <div className="form-group">
            <div className="mb-2">
              <button
                className="btn btn-lg btn-primary js-btn-prev d-none"
                type="button"
              ></button>
              <button
                className={`btn btn-lg  btn-block btn-solid js-btn-next ${buttonCssClass}`}
                id="confirm_pay"
                name="confirm_pay"
                type="button"
                onClick={validate}
              >
                Confirm and pay ${confirmForm.price}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Confirm;
