import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AlertBox,
  AlertBoxType,
  Button,
  CheckboxControl,
  RadioButtonControl,
} from "./Common";
import { PatientDetails, Prescription } from "../models";
import TextareaControl from "./Common/TextareaControl";
import isEmpty from "lodash/isEmpty";
import { sendGAEvent } from "../helpers";

interface Props {
  patient: PatientDetails;
  prescription: Prescription;
  setPrescription: Function;
  handleNextClick: Function;
}
const PrescriptionDetails = React.memo(
  ({ patient, prescription, setPrescription, handleNextClick }: Props) => {
    const [focusName, setFocusName] = useState("");
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [isButtonActive, setIsButtonActive] = useState(false);
    const [displayAntibioticsAlert, setDisplayAntibioticsAlert] =
      useState(false);
    const [displayWeightlossAlert, setDisplayWeightlossAlert] = useState(false);

    const [displayOtherMedications, setDisplayOtherMedications] =
      useState(false);
    const [displayAllergicMedications, setDisplayAllergicMedications] =
      useState(false);

    const requiredProps = useRef([
      "antibiotics",
      "weightloss",
      "medication",
      "condition",
      "takingOtherMedications",
      "allergic",
    ]);

    const yesNoOptions = [
      {
        id: "yes",
        value: "yes",
        label: "Yes",
      },
      {
        id: "no",
        value: "no",
        label: "No",
      },
    ];

    const toggleOtherMedications = (value: string) => {
      setDisplayOtherMedications(value === "yes");
      if (
        value === "yes" &&
        !requiredProps.current.includes("otherMedications")
      ) {
        const index = requiredProps.current.indexOf("takingOtherMedications");
        requiredProps.current.splice(index + 1, 0, "otherMedications");
      }
      if (
        value !== "yes" &&
        requiredProps.current.includes("otherMedications")
      ) {
        const index = requiredProps.current.indexOf("otherMedications");
        requiredProps.current.splice(index, 1);
      }
    };

    const toggleAllergicMedications = (value: string) => {
      setDisplayAllergicMedications(value === "yes");
      if (
        value === "yes" &&
        !requiredProps.current.includes("allergicMedications")
      ) {
        const index = requiredProps.current.indexOf("allergic");
        requiredProps.current.splice(index + 1, 0, "allergicMedications");
      }
      if (
        value !== "yes" &&
        requiredProps.current.includes("allergicMedications")
      ) {
        const index = requiredProps.current.indexOf("allergicMedications");
        requiredProps.current.splice(index, 1);
      }
    };

    const handleChange = (e: any): void => {
      let { name, value, checked } = e.target;
      // console.log("handleChange", name, value, checked);
      if (name === "antibiotics") {
        setDisplayAntibioticsAlert(value === "yes");
      } else if (name === "weightloss") {
        setDisplayWeightlossAlert(value === "yes");
      } else if (name === "takingOtherMedications") {
        toggleOtherMedications(value);
      } else if (name === "allergic") {
        toggleAllergicMedications(value);
      } else if (name === "agree") {
        value = checked;
      }
      let newPrescriptionDetails = {
        ...prescription,
        [name]: value,
      };
      // console.log("handleChange", newPrescriptionDetails);
      setPrescription(newPrescriptionDetails);
      if (validationErrors.includes(name)) {
        validate(false, false, newPrescriptionDetails);
      }
      updateButtonIsActive(newPrescriptionDetails);
      setFocusName("");
    };

    const updateButtonIsActive = useCallback(
      (prescription: Prescription): void => {
        let isButtonActive = true;
        if (
          prescription.antibiotics === "yes" ||
          prescription.weightloss === "yes"
        ) {
          isButtonActive = false;
        } else {
          const map = new Map(Object.entries(prescription));
          requiredProps.current.forEach((prop) => {
            if (isEmpty(map.get(prop))) {
              isButtonActive = false;
            }
          });
          if (!prescription.agree) {
            isButtonActive = false;
          }
        }
        setIsButtonActive(isButtonActive);
      },
      [requiredProps]
    );

    const validate = (
      proceedToNext: boolean,
      scrollToError: boolean = false,
      newPrescription?: Prescription
    ) => {
      let errorsExist = false;
      let focusName = "";
      const validationErrors: string[] = [];
      const prescriptionToValidate = newPrescription || prescription;
      setValidationErrors([]);

      const addError = (fieldName: string) => {
        if (!errorsExist) {
          focusName = fieldName;
        }
        validationErrors.push(fieldName);
        errorsExist = true;
      };
      if (prescriptionToValidate.antibiotics === "yes") {
        addError("antibiotics");
      }
      if (prescriptionToValidate.weightloss === "yes") {
        addError("weightloss");
      }
      const map = new Map(Object.entries(prescriptionToValidate));
      requiredProps.current.forEach((prop) => {
        if (isEmpty(map.get(prop))) {
          addError(prop);
        }
      });
      if (!prescriptionToValidate.agree) {
        addError("agree");
      }

      if (errorsExist) {
        setTimeout(() => {
          setValidationErrors(validationErrors);
          if (scrollToError) {
            let top = 0;
            switch (focusName) {
              case "antibiotics":
              case "weightloss":
                break;
              case "medication":
                top = 290;
                break;
              case "condition":
                top = 570;
                break;
              default:
                top = 710;
                break;
            }

            setTimeout(() => {
              window.scrollTo({
                top: top,
                left: 0,
                behavior: "smooth",
              });
            });
          }
        });
        setFocusName(focusName);
      } else if (proceedToNext) {
        sendGAEvent(
          "prescription_details",
          8,
          "Prescription Details",
          patient.maskedEmail,
          {
            prescription_type: prescription.repeat ? "repeat" : "new",
            value: prescription.price,
          }
        );
        handleNextClick();
      }
    };

    useEffect(() => {
      if (!isEmpty(prescription)) {
        setDisplayAntibioticsAlert(prescription.antibiotics === "yes");
        setDisplayWeightlossAlert(prescription.weightloss === "yes");
        toggleOtherMedications(prescription.takingOtherMedications);
        toggleAllergicMedications(prescription.allergic);
      }
      updateButtonIsActive(prescription);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        <div
          className="cs-chkin-form-panel js-active"
          data-animation="fadeIn"
          id="drugPanel"
        >
          <div className="col-11">
            <p className="cs-chkin-form-step-heading mb-5">
              Repeat Prescription
            </p>

            {displayAntibioticsAlert && (
              <AlertBox type={AlertBoxType.Info}>
                You will need to speak to a GP in relation to specific
                symptoms.&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="text-link navToConsult"
                  href="https://consult.oursage.com.au"
                >
                  Start a Telehealth Consult.
                </a>
              </AlertBox>
            )}

            <RadioButtonControl
              label="Do you require antibiotics?"
              name="antibiotics"
              id="antibiotics"
              options={yesNoOptions}
              value={prescription.antibiotics}
              highlight={prescription.antibiotics === "yes"}
              includeAsterix={true}
              onChange={handleChange}
              error={validationErrors.includes("antibiotics")}
            />

            <RadioButtonControl
              label="Do you require weight loss injectables?"
              name="weightloss"
              id="weightloss"
              options={yesNoOptions}
              value={prescription.weightloss}
              highlight={prescription.weightloss === "yes"}
              includeAsterix={true}
              onChange={handleChange}
              error={validationErrors.includes("weightloss")}
            />

            {displayWeightlossAlert && (
              <AlertBox type={AlertBoxType.Info}>
                Our Sage GPs do not provide prescriptions for weight loss
                injectables.
              </AlertBox>
            )}

            <h3 className="cs-chkin-form-step-heading">
              What medication do you require?
            </h3>
            <TextareaControl
              label="Medication required"
              text={prescription.medication}
              id="medication"
              name="medication"
              subtext="Max. length 150 characters"
              onChange={handleChange}
              error={validationErrors.includes("medication")}
              focus={focusName === "medication"}
            />

            <AlertBox type={AlertBoxType.Info}>
              If you're unable to identify the medication required you will need
              to speak to a GP in relation to specific symptoms.&nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                className="text-link navToConsult"
                href="https://consult.oursage.com.au"
              >
                Start a Telehealth Consult.
              </a>
            </AlertBox>

            <h3 className="cs-chkin-form-step-heading">
              What condition is the medication for?
            </h3>
            <TextareaControl
              text={prescription.condition}
              id="condition"
              name="condition"
              onChange={handleChange}
              error={validationErrors.includes("condition")}
              focus={focusName === "condition"}
            />

            <RadioButtonControl
              label="Are you taking any other medication?"
              name="takingOtherMedications"
              id="takingOtherMedications"
              value={prescription.takingOtherMedications}
              options={yesNoOptions}
              includeAsterix={true}
              onChange={handleChange}
              error={validationErrors.includes("takingOtherMedications")}
            />

            {displayOtherMedications && (
              <TextareaControl
                label="List medications"
                text={prescription.otherMedications}
                id="otherMedications"
                name="otherMedications"
                onChange={handleChange}
                error={validationErrors.includes("otherMedications")}
                focus={focusName === "otherMedications"}
              />
            )}

            <RadioButtonControl
              label="Are you allergic to any medication?"
              name="allergic"
              id="allergic"
              value={prescription.allergic}
              options={yesNoOptions}
              includeAsterix={true}
              onChange={handleChange}
              error={validationErrors.includes("allergic")}
            />

            {displayAllergicMedications && (
              <TextareaControl
                label="List medications"
                text={prescription.allergicMedications}
                id="allergicMedications"
                name="allergicMedications"
                subtext="Max. length 150 characters"
                onChange={handleChange}
                error={validationErrors.includes("allergicMedications")}
                focus={focusName === "allergicMedications"}
              />
            )}

            <div className="form-group m-0 mb-5 p-2" id="drug_consult">
              <p className="body-text">
                I acknowledge the recommendation to consult a GP in person and
                that the prescribing of medication is subject to the clinical
                judgment of the consulting healthcare professional. I will
                discuss with a GP or pharmacist the potential side effects or
                interactions with other current medications I'm taking, and
                review the&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="text-link navToConsult"
                  href="https://www.nps.org.au/medicine-finder"
                >
                  Consumer Medical Information.
                </a>
              </p>

              <CheckboxControl
                id="agree"
                name="agree"
                label="I agree"
                checked={prescription.agree}
                error={validationErrors.includes("agree")}
                onClick={handleChange}
              />
            </div>

            <div className="form-group">
              <Button
                label="Next"
                handleClick={() => validate(true, true, undefined)}
                isLoading={false}
                isActive={isButtonActive}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default PrescriptionDetails;
