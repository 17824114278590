import React, { useEffect, useRef, useState } from "react";
import { AlertBox, AlertBoxType } from "./Common";
import { PatientDetails, Prescription, Session } from "../models";
import { BuildUrl, METHOD, sendGAEvent } from "../helpers";
import { REPEAT_CONSULT_TYPE } from "../constants";

interface Props {
  session: Session;
  prescription: Prescription;
  patient: PatientDetails;
  feeText: string;
  setIsError: Function;
  paymentResult: Function;
}

const Payment = React.memo(
  ({
    session,
    prescription,
    patient,
    feeText,
    setIsError,
    paymentResult,
  }: Props) => {
    const [isLoading, setisLoading] = useState(true);
    const paymentEventRecieved = useRef(false);

    // Payment window will send a postMessage event with payment result
    window.addEventListener(
      "message",
      (event) => {
        const data = event.data;
        if (!data || data.result == null) {
          return;
        }

        if (!paymentEventRecieved.current) {
          if (data.result === "1") {
            sendGAEvent("purchase", 10, "Purchase", patient.maskedEmail, {
              prescription_type: prescription.repeat ? "repeat" : "new",
              ecommerce: {
                value: prescription.price,
                currency: "AUD",
                items: [
                  {
                    item_name: prescription.repeat ? "repeat" : "new",
                  },
                ],
              },
            });
            paymentResult(true);
          } else {
            sendGAEvent(
              "payment_failure",
              10,
              "Purchase",
              patient.maskedEmail,
              {
                prescription_type: prescription.repeat ? "repeat" : "new",
                ecommerce: {
                  value: prescription.price,
                  currency: "AUD",
                  items: [
                    {
                      item_name: prescription.repeat ? "repeat" : "new",
                    },
                  ],
                },
              }
            );
            paymentResult(false);
          }
          paymentEventRecieved.current = true;
        }

        // 25 minute timeout
        let timeoutId;
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {}, 1500000);
      },
      false
    );

    useEffect(() => {
      async function loadPaymentForm() {
        try {
          const formData = new FormData();
          formData.append("cid", session.cid.toString());
          formData.append("apptype", REPEAT_CONSULT_TYPE.toString());
          formData.append("paymentAmount", session.repeatPrice.toString());
          formData.append("pname", `${patient.fName} ${patient.lName}`);
          formData.append("pid", patient.prn);
          formData.append("fmem", "");
          formData.append("docid", "0");
          formData.append("bookreason", "");
          const url = BuildUrl("load_payment");
          const response = await fetch(url, {
            method: METHOD.POST,
            headers: {
              "X-Requested-With": "XMLHttpRequest",
            },
            body: formData,
          });

          if (response.body) {
            let html = "";
            const reader = response.body.getReader();
            while (true) {
              // The `read()` method returns a promise that
              // resolves when a value has been received.
              const { done, value } = await reader.read();
              // Result objects contain two properties:
              // `done`  - `true` if the stream has already given you all its data.
              // `value` - Some data. Always `undefined` when `done` is `true`.
              if (done) {
                let paymentUrl = "";
                let startIndex = html.indexOf(
                  process.env.REACT_APP_PAYMENT_URL || ""
                );
                if (startIndex > 0) {
                  paymentUrl = html.substring(startIndex);
                  paymentUrl = paymentUrl.slice(0, paymentUrl.indexOf(`'`));

                  const payFormElement: HTMLIFrameElement =
                    document.getElementById("payFrm") as HTMLIFrameElement;
                  if (payFormElement === null) {
                    throw new Error(`Pay Form is not mounted`);
                  } else {
                    payFormElement.src = paymentUrl;
                    setisLoading(false);
                  }
                } else {
                  setIsError(true);
                  console.log("Counld not find payment url");
                }
                return html;
              }
              html += new TextDecoder().decode(value);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
      loadPaymentForm();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div
        className="cs-chkin-form-panel js-active"
        data-animation="fadeIn"
        id="paymentPanel"
      >
        <div className="col-11 d-flex flex-column rowGap-2">
          <p className="cs-chkin-form-step-heading mb-4">
            Enter payment details
          </p>

          <AlertBox type={AlertBoxType.Info}>{feeText}</AlertBox>

          <div id="payment_form">
            {isLoading && (
              <div id="loadPform" className="row">
                <div className="col-md-8 col-md-offset-2">
                  <p
                    className="alert alert-info text-center"
                    style={{ fontSize: 16, color: "#14B8E7" }}
                  >
                    <img src="/public/images/load2.gif" alt="loading" />{" "}
                    Processing your request. Please wait...
                  </p>
                </div>
              </div>
            )}
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              id="payFrm"
              name="payFrm"
              width="100%"
              height="400"
              style={{ border: 0 }}
              data-pbk="MA=="
              data-pm="MDAwMDAwMDAwMA=="
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
);

export default Payment;
