import { useCallback, useEffect, useMemo, useState } from "react";
import { EmergencyNextOfKinForm, PatientDetails, Session } from "../models";
import { isEmpty } from "lodash";
import { Button, CheckboxControl, InputControl, SelectControl } from "./Common";
import { InitialEmergencyNextOfKinForm } from "../constants";
import { CallApi, MapObjectToFormData, METHOD, sendGAEvent } from "../helpers";

interface Props {
  session: Session;
  patient: PatientDetails;
  setIsError: Function;
  handleNextClick: Function;
}

const EmergencyNextOfKin = ({
  session,
  patient,
  setIsError,
  handleNextClick,
}: Props) => {
  const [emergencyNextOfKinForm, setEmergencyNextOfKinForm] =
    useState<EmergencyNextOfKinForm>(InitialEmergencyNextOfKinForm);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [focusName, setFocusName] = useState("");
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const requiredProps = useMemo(
    () => [
      "emer_fname",
      "emer_lname",
      "emer_contact_no",
      "emer_rel",
      "kin_fname",
      "kin_lname",
      "kin_contact_no",
      "kin_rel",
    ],
    []
  );

  const relationshipOptions = [
    {
      value: "",
      label: "",
    },
    {
      value: "Spouse/Partner",
      label: "Spouse/Partner",
    },
    {
      value: "Parent",
      label: "Parent",
    },
    {
      value: "Child",
      label: "Child",
    },
    {
      value: "Other relative",
      label: "Other relative",
    },
    {
      value: "Friend/Neighbour",
      label: "Friend/Neighbour",
    },
    {
      value: "Not stated/inadequately described",
      label: "Not stated/inadequately described",
    },
  ];

  const handleChange = (e: any): void => {
    const { name, value } = e.target;
    const newEmergencyNextOfKinDetails = {
      ...emergencyNextOfKinForm,
      [name]: value,
    };
    setEmergencyNextOfKinForm(newEmergencyNextOfKinDetails);
    updateButtonIsActive(newEmergencyNextOfKinDetails);
    setFocusName("");
  };

  const updateButtonIsActive = useCallback(
    (emergencyNextOfKinForm: EmergencyNextOfKinForm): void => {
      let isButtonActive = true;
      const map = new Map(Object.entries(emergencyNextOfKinForm));
      requiredProps.forEach((prop) => {
        if (isEmpty(map.get(prop))) {
          isButtonActive = false;
        }
      });
      setIsButtonActive(isButtonActive);
    },
    [requiredProps]
  );

  const validate = async () => {
    let errorsExist = false;
    setFocusName("");
    setValidationErrors([]);

    const validationErrors: string[] = [];
    const addError = (fieldName: string) => {
      if (!errorsExist) {
        setTimeout(() => {
          setFocusName(fieldName);
        });
      }
      validationErrors.push(fieldName);
      errorsExist = true;
    };

    const map = new Map(Object.entries(emergencyNextOfKinForm));
    requiredProps.forEach((prop) => {
      if (isEmpty(map.get(prop))) {
        addError(prop);
      }
    });

    if (errorsExist) {
      setTimeout(() => setValidationErrors(validationErrors));
    } else {
      await saveEmergencyNextOfKinDetails();
    }
  };

  useEffect(() => {
    updateButtonIsActive(emergencyNextOfKinForm);
  }, [emergencyNextOfKinForm, updateButtonIsActive]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSameAsAboveClick = (e: any): void => {
    const { checked } = e.target;
    const newEmergencyNextOfKinForm = {
      ...emergencyNextOfKinForm,
      emer_tick: checked,
      kin_fname: checked ? emergencyNextOfKinForm.emer_fname : "",
      kin_lname: checked ? emergencyNextOfKinForm.emer_lname : "",
      kin_contact_no: checked ? emergencyNextOfKinForm.emer_contact_no : "",
      kin_rel: checked ? emergencyNextOfKinForm.emer_rel : "",
    };
    setEmergencyNextOfKinForm(newEmergencyNextOfKinForm);
  };

  const saveEmergencyNextOfKinDetails = async () => {
    const newPatientDetails = {
      ...patient,
      emer_fname: emergencyNextOfKinForm.emer_fname,
      emer_lname: emergencyNextOfKinForm.emer_lname,
      emer_contact_no: emergencyNextOfKinForm.emer_contact_no,
      emer_rel: emergencyNextOfKinForm.emer_rel,
      kin_fname: emergencyNextOfKinForm.kin_fname,
      kin_lname: emergencyNextOfKinForm.kin_lname,
      kin_contact_no: emergencyNextOfKinForm.kin_contact_no,
      kin_rel: emergencyNextOfKinForm.kin_rel,
      cid: session.cid,
    };

    const body = MapObjectToFormData(newPatientDetails);

    const response = await CallApi(
      "updt_details",
      METHOD.POST,
      body,
      null,
      setIsLoading,
      setIsError
    );
    if (response.status) {
      sendGAEvent(
        "emergency_contact_details",
        7,
        "Emergency Contact Details",
        newPatientDetails.maskedEmail
      );
      handleNextClick(newPatientDetails);
    }
  };

  return (
    <>
      <div className="cs-chkin-form-panel js-active" data-animation="fadeIn">
        <div className="cs-chkin-form-content step col-xs-11">
          <h3 className="cs-chkin-form-step-heading">
            Emergency contact details
          </h3>

          <InputControl
            type="text"
            label="First Name*"
            name="emer_fname"
            value={emergencyNextOfKinForm.emer_fname}
            onChange={handleChange}
            focus={focusName === "emer_fname"}
          />

          <InputControl
            type="text"
            label="Last Name*"
            name="emer_lname"
            value={emergencyNextOfKinForm.emer_lname}
            onChange={handleChange}
            focus={focusName === "emer_lname"}
            error={validationErrors.includes("emer_lname")}
          />

          <InputControl
            type="mobile"
            label="Phone Number*"
            name="emer_contact_no"
            value={emergencyNextOfKinForm.emer_contact_no}
            maxlength={10}
            onChange={handleChange}
            focus={focusName === "emer_contact_no"}
            error={validationErrors.includes("emer_contact_no")}
          />

          <SelectControl
            id="emer_rel"
            name="emer_rel"
            label="Relationship to patient*"
            options={relationshipOptions}
            value={emergencyNextOfKinForm.emer_rel}
            onChange={handleChange}
            focus={focusName === "emer_rel"}
            error={validationErrors.includes("emer_rel")}
          />

          <h3 className="cs-chkin-form-step-heading mb-2">
            Next of Kin contact details
          </h3>

          <CheckboxControl
            id="emer_tick"
            name="emer_tick"
            label="Same as above"
            checked={emergencyNextOfKinForm.emer_tick}
            onClick={handleSameAsAboveClick}
          />

          <InputControl
            type="text"
            label="First Name*"
            name="kin_fname"
            value={emergencyNextOfKinForm.kin_fname}
            onChange={handleChange}
            focus={focusName === "kin_fname"}
            error={validationErrors.includes("kin_fname")}
          />

          <InputControl
            type="text"
            label="Last Name*"
            name="kin_lname"
            value={emergencyNextOfKinForm.kin_lname}
            onChange={handleChange}
            focus={focusName === "kin_lname"}
            error={validationErrors.includes("kin_lname")}
          />

          <InputControl
            type="mobile"
            label="Phone Number*"
            name="kin_contact_no"
            value={emergencyNextOfKinForm.kin_contact_no}
            maxlength={10}
            onChange={handleChange}
            focus={focusName === "kin_contact_no"}
            error={validationErrors.includes("kin_contact_no")}
          />

          <SelectControl
            id="kin_rel"
            name="kin_rel"
            label="Relationship to patient*"
            options={relationshipOptions}
            value={emergencyNextOfKinForm.kin_rel}
            onChange={handleChange}
            focus={focusName === "kin_rel"}
            error={validationErrors.includes("kin_rel")}
          />

          <div className="form-group">
            <button
              className="btn btn-lg btn-primary js-btn-prev d-none"
              type="button"
              title="Prev"
            ></button>
            <Button
              label="Next"
              handleClick={validate}
              isLoading={isLoading}
              isActive={isButtonActive}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EmergencyNextOfKin;
