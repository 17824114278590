import React from "react";
import { AlertBox, AlertBoxType } from "./Common";
import { sendGAEvent } from "../helpers";
import { Session } from "../models";

interface Props {
  session: Session;
  onChange: Function;
}

const SelectPrescriptionType = React.memo(({ session, onChange }: Props) => {
  const prescriptionTypeClick = (value: string) => {
    sendGAEvent("prescription_type", 1, "Prescription Type", undefined, {
      prescription_type: value,
      value: value === "repeat" ? session.repeatPrice : session.newPrice,
    });
    if (value === "repeat") {
      onChange(value);
    } else {
      window.location.href = "https://consult.oursage.com.au";
    }
  };

  return (
    <div className="cs-chkin-form-panel js-active" id="scriptTypePanel">
      <div className="col-11 d-flex flex-column rowGap-2">
        <h2 className="cs-chkin-form-step-heading mt-0">
          Select prescription type
        </h2>

        <div
          className="btn btn-block btn-selection np"
          id="script_repeat"
          data-np="1"
          data-price=""
          data-version="Repeat prescription"
          onClick={() => prescriptionTypeClick("repeat")}
        >
          <div>
            <div className="landingButtonHeading">
              Repeat or regular script ($<span>{session.repeatPrice}</span>)
            </div>
            <div className="landingButtonBody">
              Request a single online prescription for a regular medication.
            </div>
          </div>
          <img
            src="images/svg/cs_blue_tick.svg"
            className="ml-auto"
            alt="tick"
          />
        </div>

        <div
          className="btn btn-block btn-selection np"
          id="script_new"
          data-np="2"
          data-price=""
          data-version="New prescription"
          onClick={() => prescriptionTypeClick("new")}
        >
          <div>
            <div className="landingButtonHeading">
              New script ($<span>{session.newPrice}</span>)
            </div>
            <div className="landingButtonBody">
              Speak to an Our Sage healthcare professional to request a new
              medication or request a change to a current medication.
            </div>
          </div>
          <img
            src="images/svg/cs_blue_tick.svg"
            className="ml-auto"
            alt="tick"
          />
        </div>

        <p className="body-text">
          After a telehealth consult with a GP?&nbsp;
          <a
            className="text-link navToConsult"
            href="https://consult.oursage.com.au"
          >
            Click here.
          </a>
        </p>

        <AlertBox type={AlertBoxType.Info}>
          The prescribing of any medication is at the discretion of the
          consulting Our Sage healthcare professional and is not guaranteed.
          Please note Our Sage GPs do not provide prescriptions for controlled,
          addictive and/or S4D or S8 medications.
        </AlertBox>
      </div>
    </div>
  );
});

export default SelectPrescriptionType;
